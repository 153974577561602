<template>
  <div id="app">
    <component :is="layout">
      <router-view></router-view>
    </component>
  </div>
</template>

<script>
const default_layout = "default";
export default {
  name: "app",
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    }
  }
};
</script>

<style>
@import "./assets/styles/global.css";
@import "./assets/styles/custom.css";
@import "./assets/styles/animations.css";
@import "./assets/icons/fontawesome/css/all.min.css";
</style>
