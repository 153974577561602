import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        CURRENT_SECTION: "intro"
    },
    mutations: {
        SET_CURRENT_SECTION(state, section) {
            state.CURRENT_SECTION = section;
        }
    },

    actions: {
        SET_CURRENT_SECTION(context, section) {
            context.commit("SET_CURRENT_SECTION", section);
        }
    }
});

export default store;
