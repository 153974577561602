import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import VueScrollReveal from 'vue-scroll-reveal'
import Meta from 'vue-meta'
import VueLazyload from 'vue-lazyload'

const Default = () => import('@/layouts/Default')
const Navigation = () => import('@/layouts/Navigation')

Vue.config.productionTip = false

Vue.component('default-layout', Default)
Vue.component('navigation-layout', Navigation)
Vue.use(Meta)
Vue.use(VueScrollReveal, {
    class: 'v-scroll-reveal', // A CSS class applied to elements with the v-scroll-reveal directive; useful for animation overrides.
    duration: 1000,
    scale: 1,
    distance: '10px'
})
Vue.use(VueLazyload, { preLoad: 1.3, error: 'dist/error.png', loading: '', attempt: 1 })

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
