import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    base: '/',
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            meta: {
                layout: 'navigation'
            },
            component: () => import(`@/views/Index`)
        },

        {
            path: '/blog',
            name: 'Home',
            meta: {
                layout: 'navigation'
            },
            component: () => import(`@/views/ComingSoon`)
        },

        {
            path: '/store',
            name: 'Home',
            meta: {
                layout: 'navigation'
            },
            component: () => import(`@/views/ComingSoon`)
        }
    ]
})

export default router
